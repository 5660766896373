@import './componentStyles/stepper';
@import './componentStyles/custom-fields';
@import './componentStyles/profile-menu';
@import './componentStyles/login';
@import './componentStyles/animations';
@import './scss/custom/_forms.scss';
@import './dashboard-dark/main.scss';
@import './dashboard-dark/variables-custom';
@import '../../node_modules/font-awesome/css/font-awesome.css';
//@import '../../node_modules/font-awesome/scss/font-awesome';
@import '../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
@import '../../node_modules/react-picky/dist/picky.css';
@import '../../node_modules/react-toggle/style.css';
@import '../../node_modules/@webscopeio/react-textarea-autocomplete/style.css';
@import '../../node_modules/react-s-alert/dist/s-alert-default.css';
@import '../../node_modules/react-s-alert/dist/s-alert-css-effects/slide.css';
@import './scss/bootstrap.scss';
@import './flags.scss';
@import './componentStyles/fusion-components.scss';

body {
  padding: 0;
  margin: 60px 0 0!important;
}

.separator {
  height: 450px;
  overflow: scroll;
}

.breadcrumbs {
  margin: 15px;
}

.error-text {
  color: #8b0000;
}

.error-alert {
}

select[multiple], select[size] {
  height: auto;
}

button:disabled,
button[disabled] {
  cursor: not-allowed;
}

a.list-group-item:hover, div.list-group-item:hover, a.list-group-item:focus, div.list-group-item:focus {
  text-decoration: none;
  color: #cfd2da;
  cursor: auto;
  background-color: #434857;
}

.modal a.list-group-item:hover,
.modal div.list-group-item:hover,
.modal a.list-group-item:focus,
.modal div.list-group-item:focus {
  text-decoration: none;
  color: #434857;
  cursor: auto;
  background-color: #fff;
  border: none;
}


.modal .list-group-item {
  text-decoration: none;
  color: #434857;
  cursor: auto;
  background-color: #fff;
  border: none;
}

.modal .list-group-item.active {
  text-decoration: none;
  color: #434857;
  cursor: auto;
  background-color: #ebecee;
  border: none;
}

.blurry-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(8 8 8 / 55%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  overflow: hidden; /* Prevents scrolling */
  pointer-events: none; /* Disables clicks */
}

.page-item .page-link{
  background-color: #434857;
  color: #ffffff;
  border: 1px solid #252830;
  &:hover {
    background-color: #51586a;
  }
}

.internet-explorer-alert {
  text-align: center;
  background-color: red;
  color: white;
  font-size: 16px;
  padding: 4px 0 2px;
  min-height: auto;
  border: none;
}

.dot {
  display: inline-block;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  background-color: $brand-primary;

  &.primary {
    background-color: $brand-primary;
  }

  &.success {
    background-color: $brand-success;
  }

  &.info {
    background-color: $brand-info;
  }

  &.warning {
    background-color: $brand-warning;
  }

  &.danger {
    background-color: $brand-danger;
  }

  &.unknown {
    background-color: $gray;
  }
}

.small-status-dot {
  display: inline-block;
  border-radius: 50%;
  width: 9px;
  height: 9px;
}

.regular-status-dot {
  display: inline-block;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  vertical-align: text-bottom;
}

.medium-status-dot {
  margin: 2px;
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
}

#scheduler {
  margin: 10px 0 0 0;

  th,
  td,
  thead,
  tbody,
  .fc-divider {
    background: inherit;
    font-weight: bold;
  }

  .fc-row,
  .fc-content, /* for gutter border */
  .fc-popover,
  .fc-list-view,
  .fc-list-heading td {
    border-color: #666;
  }
}

.vm-panel-wrap {
  .vm-panel-header {
    color: #51586a;
    background-color: #f5f5f5;
    border-color: #ddd;
    padding: 10px 15px;
    border-bottom: 1px solid transparent;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    line-height: 30px;
    font-weight: 600;
  }
}

.vm-table-wrap {
  table {
    margin-bottom: 0px;
  }
}

.record-list-search-btn {
  @media (max-width: 1200px) {
    width: 100%;
  }
}

.table-header-wrapper {
  th {
    position: relative;
    padding: 10px 15px !important;
  }

  .order {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    padding-right: 6px;
    cursor: pointer;

    .caret {
      float: right;
      margin-top: 18px !important;
    }
  }
}

.dropdown-toggle .dropdown-toggle-split::before {
  content: "";
  position: absolute!important;
  right: -15px;
  top: 12px;
  z-index: 999;
  width: 0;
  height: 0;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.show.dropdown > .btn.btn-success.dropdown-toggle-split {
  color: #fff!important;
  background-color: #117d58!important;
  border-color: #09422f!important;
}

.btn-group > .btn + .dropdown-toggle {
  border-left: 1px solid;
  padding: 6px 12px;
}

.btn-group.open .dropdown-toggle {
  box-shadow: inset 0 3px 5px rgb(0 0 0 / 13%);
}

.btn-success:active, .btn-success.active, .open > .dropdown-toggle.btn-success {
  color: #fff;
  background-color: #159c6e;
  border-color: #117d58;
}

.with-table-header {
  position: relative;

  .vm-table-wrap {
    width: 100%;
    margin-top: -41px;

    .btn-toolbar {
      margin: 0px 30px 0px 0px;
    }

    table {
      margin-bottom: 0px;
    }

    .react-bs-table {
      padding-top: 0px;

      .react-bs-container-body {
        tr:hover {
          cursor: pointer;
        }

        tr td:first-child,
        tr th:first-child {
          padding: 20px 0px !important;
        }
      }

      .react-bs-container-header {
        tr th:first-child {
          padding: 20px 0px !important;
        }
      }
    }

    .react-bs-table-search-form {
      margin-right: 12px;
      margin-bottom: 11px;

      .form-control {
        max-width: 212px;
        float: right;
        margin-bottom: 11px;
      }
    }
  }

  #printsTable_filter {
    margin-right: 100px;
  }

  #printersTable tbody tr:hover {
    color: #cfd2da;
    background-color: #434857;
    cursor: pointer;
  }
}

.react-bootstrap-table-page-btns-ul {
  margin: 0;
  float: right;
}

.vm-table-wrap {
  margin-bottom: 20px;
  border: 0 !important;

  .react-bs-table-tool-bar {
    margin-bottom: 0px;
  }

  .react-bs-table-tool-bar .row > .col-xs-6:first-child {
    pointer-events: none;
  }

  .react-bs-table-bordered {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }
}

.table-fixed {
  tbody {
    display: block;
    height: 150px;
    overflow: auto;
  }

  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
}

.table {
  width: 100% !important;

  thead {
    color: inherit;
  }

  td {
    padding: 10px 15px !important;
    vertical-align: middle;
    max-width: 110px;
    overflow: hidden;
    text-overflow: ellipsis;

    &.render-column {
      padding: 5px !important;
    }
  }

  thead .sorting::after,
  thead .sorting_asc::after {
    display: none;
  }

  thead .sorting_desc::after {
    display: none;
  }

  thead .sorting {
    background-image: url(../images/sort_both.png);
    background-repeat: no-repeat;
    background-position: center right;
  }

  thead .sorting_asc {
    background-image: url(../images/sort_asc.png);
    background-repeat: no-repeat;
    background-position: center right;
  }

  thead .sorting_desc {
    background-image: url(../images/sort_desc.png);
    background-repeat: no-repeat;
    background-position: center right;
  }

  // Work Instructions style applies everywhere excluding the Modal Window.
  .work-instructions-table > tr {
    color: #fff;
  }
}

.moveFreeAxes {
  margin-right: 11.6px!important;
}

.allPointerEvents {
  pointer-events: all!important;
}

.part_image {
  width: 80px;
  height: auto;
}

.vm-toggle-wrap {
  margin-left: 20px;
  height: 26px;
  display: inline-block;
  margin-top: 2px;
  vertical-align: top;

  .switch {
    margin: 0px 5px;
  }

  .text {
    vertical-align: top;
    font-weight: 400;
    line-height: 26px;
  }
}

.panel_heading {
  line-height: 30px;
  font-weight: 700;
  display: flex;
  align-items: center;
}

.run-prints-table {
  margin-bottom: 120px;
}

.panel-body-bordered {
  margin-bottom: 21px;
  min-height: 30px;
  height: auto;
  border: solid 1px #fff;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

table.no-outer-border td,
table.no-outer-border th {
  border: 1px solid #434857;
  overflow-wrap: break-word;
}

table.no-outer-border tr:first-child th {
  border-top: 0;
}

table.no-outer-border tbody tr td {
  border-bottom: 0;
}

table.no-outer-border tr td:first-child,
table.no-outer-border tr th:first-child {
  border-left: 0;
}

table.no-outer-border tr td:last-child,
table.no-outer-border tr th:last-child {
  border-right: 0;
}

.bordered-tbody td {
  border: 1px solid #434857;
}

.border-info {
  border-color: #9F86FF !important;
}

.border-primary {
  border-color: #1CA8DD !important;
}


.orderQuoteRequired {
  &:hover {
    background-color: unset!important;
  }
}

.quoteStepModalShort {
  max-width: 200px;
  word-break: break-word;
}

.quoteProcessStepModalNoBorder {
  border: none!important;
}

.quoteProcessStepModal {
  border: none!important;

  & > tr:last-child {
    border: none;
  }

  & > tr th, td, p {
    vertical-align: middle !important;
    margin: 0 !important;
  }
}

.quoteProcessStepSelectData {
  overflow-y: auto;
  max-height: 400px;

  &Disabled {
    pointer-events: none!important;
    cursor: not-allowed!important;
  }
}

.quoteProcessStepHr {
  width: 100%;
  display: block;
  border-bottom: 1px solid black;
}

.quoteProcessStepTitle {
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  white-space: nowrap;
  margin-left: 5px!important;
}

.quoteProcessStepSelect {
  white-space: nowrap;
  padding: 5px 7px;
  cursor: pointer;
  margin-bottom: 5px!important;
  border-radius: 3px 0 0 3px;
  font-weight: 500;
  max-width: 265px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;

  &:hover {
    background-color: #5e6474;
    color: #fff;
  }
}

.quoteProcessStepSelectActive {
  background-color: #434857;
  color: #fff;
  border-radius: 3px;
}

.color-red {
  color: #dc3545;
}

.quoteProcessStepSelectDisabled {
  background-color: rgba(89, 90, 93, 0.53);
  color: #fff;
}

.quoteProcessStepModalTotal {
  font-weight: 600;
  margin: 0;
}

.table .quoteProcessStepModalTotalUnit {
  padding: 4px 0 4px 8px!important;
}

.quoteProcessStepModalTotalList {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 0 4px 8px!important;
}

.table .quoteProcessStepModalPrice {
  padding: 0!important;
  padding-left: 5px!important;
  max-width: 100px;
}

.quoteProcessStepModalIconsContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  & > button:first-child {
    margin-right: 5px;
  }
}

.quoteProcessStepModalButton {
  padding: 2px 8px!important;
  font-size: 16px;
}

.quoteProcessStepPerPieceDuration {
  padding: 4px 8px!important;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 0!important;

  & + div > p {
    margin-bottom: 0!important;
    margin-right: 30px;
  }
}

.quoteProcessStepScheduling {
  margin-left: 8px!important;
  margin-bottom: 3px!important;
}

.quoteProcessStepSchedulingTitle {
  font-weight: 500;
}

.quoteProcessStepModalButtonIcon {
  height: 21px!important;
}

.quoteProcessStepModalInput {
  width: 40px;
}

.quoteProcessStepModalFooter > button {
  padding: 5px 18px;
}

.quoteProcessStepModalInput::-webkit-outer-spin-button,
.quoteProcessStepModalInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.quoteProcessStepModalInput[type=number] {
  -moz-appearance: textfield;
}

.quoteProcessStepModalInputTextPrice {
  max-width: 150px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0;
  height: 23px;
}


.table-hover > tbody > tr {
  cursor: default;
}

thead td.sorting-td {
  cursor: pointer;
}

.table-hover > tbody > tr.selected-row:hover {
  background-color: #d9edf7;
}

.selected-row {
  background-color: #d9edf7!important;
  color: #888888!important;
}

.pagination-panel ul.pagination li a {
  border-color: #fff;
  color: #fff;
}

.btn-info-outline {
  color: #8b6cff;
  background-color: transparent;
  border-color: #8b6cff;

  &:hover {
    color: #fff;
    background-color: #8b6cff;
    box-shadow: none;
  }
}

.g7 {
  --bs-gutter-x: 7rem !important;
}

#printersTable {
  thead td.sorting-td {
    padding: 0 !important;

    button {
      width: 100%;
      background-color: transparent;
      color: #fff;
      border: 0;
      padding: 12px 15px;
      text-align: left;
      line-height: 1.5;
    }

    button:focus,
    button:active {
      outline: none;
    }
  }
}

.capitalize {
  text-transform: capitalize!important;
}

#home-order-table {
  overflow-x: scroll;

  .wrap {
    word-break: break-word;
  }

  table {
    margin-bottom: 0;
  }
}

.navbar-impersonate {
  background-color: #203455;
}

// KLUDGE: In order to prevent the main nav from breaking onto a separate, second line and hiding
// the breadcrumbs as well as other downstream negative consequences, we need to override all of
// the bootstrap classes responsible for collapsible menus.
// TODO: Eliminate in favor of standard boostrap options when upgrading to Bootstrap >= 4
@media (min-width: 991px) and (max-width: 1030px) {
  // Ensure that styles apply only to top header
  // .navbar-fixed-top {
  .navbar-header {
    float: none;
  }

  .navbar-left,
  .navbar-right {
    float: none !important;
  }

  .navbar-toggle {
    display: block;
  }

  .navbar-collapse {
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
  }

  // .navbar-fixed-top {
  //   top: 0;
  //   border-width: 0 0 1px;
  // }

  .navbar-collapse.collapse {
    display: none !important;
  }

  .navbar-nav {
    float: none !important;
    // KLUDGE: Derived from navbar.less line 232
    margin: 7.25px -15px;
  }

  .navbar-nav > li {
    float: none;
  }

  .navbar-nav > li > a {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .collapse.in {
    display: block !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
  }

  // KLUDGE: Repositions the dropdown menu that will occur in collapsible navs due to the change
  // to the breakpoint
  .collapse.in .navbar-nav > li > .dropdown-menu {
    margin-right: 725px;
  }

  .collapse.in .dropdown-menu > li.no-link-menu-item > a {
    background-color: $navbar-inverse-link-hover-color;
    color: $dropdown-link-color !important;
  }

  .navbar-collapse.collapse.in {
    display: block !important;
  }
  // }

  .navbar-toggler {
    display: block;
  }
}

@media (min-width: 1030px) {
  .navbar-toggler {
    display: none;
  }
}

.dropdown-menu.show {
  font-size: 14px !important;
}

.dropdown-menu > li.no-link-menu-item > a:hover,
.dropdown-menu > li.no-link-menu-item > a:focus,
.dropdown-menu > li.no-link-menu-item > a {
  background-color: $navbar-inverse-link-hover-color;
  @media (max-width: 1030px) {
    background-color: $navbar-inverse-bg;
    color: $navbar-inverse-link-hover-color !important;
  }
  @media (max-width: 991px) {
    color: $navbar-inverse-link-color !important;
  }
  cursor: default;
}

.fixed-select-pieces-panel {
  position: sticky;
  top: 51px;
  width: 100%;
  z-index: 1;
}

.filters-label {
  margin-top: 8px;
}

// form groups

.form-group {
  &--third-party-provider {
    min-height: 110px;
  }
}

//custom checkbox
.new-custom-checkbox .form-check {
  display: flex;
  align-items: center;
  position: relative;
}

.new-custom-checkbox .form-check-input {
  opacity: 0;
  width: 0;
  height: 0;
}

.new-custom-checkbox .form-check-label {
  position: relative;
  padding-left: 40px;
  cursor: pointer;
  font-size: 14px;
  color: #cfd2da;
}

.new-custom-checkbox .form-check-label::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 34px;
  height: 18px;
  background-color: #ccc;
  border-radius: 20px;
  transition: background-color 0.3s;
}

.new-custom-checkbox .form-check-label::after {
  content: "";
  position: absolute;
  left: 4px;
  top: 50%;
  transform: translateY(-50%);
  width: 14px;
  height: 14px;
  background-color: white;
  border-radius: 50%;
  transition: transform 0.3s;
}

.new-custom-checkbox .form-check-input:checked ~ .form-check-label::before {
  background-color: #1CA8DD;
}

.new-custom-checkbox .form-check-input:checked ~ .form-check-label::after {
  transform: translate(12px, -50%);
}

// helpers

.spacer-top {
  margin-top: 6px;
}

.spacer-left {
  margin-left: 6px;
}

.spacer-right {
  margin-right: 6px;
}

.d-inline-block {
  display: inline-block;
}

.d-inline {
  display: inline;
}

.w50p {
  width: 50px;
}

.w100 {
  width: 100%;
}

.wauto {
  width: auto;
}

.paddingless {
  padding: 0 !important;
}

.font-weight-200 {
  font-weight: 300 !important;
}

.font-weight-100 {
  font-weight: 100 !important;
}

.font-weight-400 {
  font-weight: 400 !important;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.mt3 {
  margin-top: 3px!important;
}

.mt5 {
  margin-top: 5px!important;
}

.mt10 {
  margin-top: 10px!important;
}

.mt15 {
  margin-top: 15px!important;
}

.mt20 {
  margin-top: 20px!important;
}

.mt30 {
  margin-top: 30px!important;
}

.mt50 {
  margin-top: 50px!important;
}

.mr5 {
  margin-right: 15px!important;
}

.mr10 {
  margin-right: 10px!important;
}

.mr15 {
  margin-right: 15px!important;
}

.mr20 {
  margin-right: 15px!important;
}

.mr30 {
  margin-right: 30px!important;
}

.mr3 {
  margin-right: 3px !important;
}

.ml0 {
  margin-left: 0px!important;
}

.ml3 {
  margin-left: 3px!important;
}

.ml5 {
  margin-left: 15px!important;
}

.ml8 {
  margin-left: 8px!important;
}

.ml15 {
  margin-left: 15px!important;
}

.ml10 {
  margin-left: 10px!important;
}

.ml30 {
  margin-left: 30px!important;
}

.mt0 {
  margin-top: 0!important;
}

.mb0 {
  margin-bottom: 0!important;
}

.mb3 {
  margin-bottom: 3px!important;
}

.mb5 {
  margin-bottom: 5px!important;
}

.mb6 {
  margin-bottom: 6px!important;
}

.mb8 {
  margin-bottom: 8px!important;
}

.mb10 {
  margin-bottom: 10px!important;
}

.mb15 {
  margin-bottom: 15px!important;
}

.mb20 {
  margin-bottom: 20px!important;
}

.mb25 {
  margin-bottom: 25px!important;
}

.mb30 {
  margin-bottom: 30px!important;
}

.mb50 {
  margin-bottom: 50px!important;
}

.ml5 {
  margin-left: 5px!important;
}

.d-flex {
  display: flex;
}

.flex-1 {
  flex: 1;
}

.flex-direction-column {
  flex-direction: column;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-start {
  justify-content: flex-start;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-between {
  justify-content: space-between;
}

.align-items-center {
  align-items: center;
}

.align-items-baseline {
  align-items: baseline;
}

.align-items-start {
  align-items: flex-start;
}

.align-items-end {
  align-items: flex-end;
}

.flex-wrap {
  flex-wrap: wrap;
}

.font-size-9 {
  font-size: 9px !important;
}

.font-size-10 {
  font-size: 10px;
}

.font-size-12 {
  font-size: 12px;
}

.font-size-13 {
  font-size: 13px;
}

.font-size-14 {
  font-size: 14px !important;
}

.font-size-16 {
  font-size: 16px;
}

.font-size-18 {
  font-size: 18px;
}

.font-size-20 {
  font-size: 20px;
}

.font-size-22 {
  font-size: 22px;
}

.font-size-24 {
  font-size: 24px;
}

.font-size-36 {
  font-size: 36px;
}

.resize-vertical {
  resize: vertical;
}

.p-relative {
  position: relative;
}

.link {
  color: $brand-primary;

  :hover {
    cursor: pointer;
  }
}

.btn {
  // Bootstrap override
  white-space: normal;
  padding: 6px 12px;
}

.hidden {
  display: none;
}

.btn-success {
  color: #fff!important;
  background-color: #1BC98E!important;
  border-color: #18b37e!important;

  &:hover {
    color: #fff!important;
    background-color: #159c6e!important;
    border-color: #117d58!important;
  }
}

.btn-primary {
  color: #fff!important;
  background-color: #1CA8DD!important;
  border-color: #1997c6!important;

  &:hover {
    color: #fff!important;
    background-color: #1686b0!important;
    border-color: #126e90!important;
  }
}

.btn-info {
  color: #fff!important;
  background-color: #9F86FF!important;
  border-color: #8b6cff!important;
}

.btn-default {
  color: #51586a!important;
  background-color: #ffffff!important;
  border-color: #e6e6e6!important;

  &:hover {
    color: #51586a!important;
    background-color: #e6e6e6!important;
    border-color: #c7c7c7!important;
  }
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-default {
  cursor: default !important;
}

.btn-group > label {
  padding: 6px 12px!important;
}

.btn.btn-active {
  box-shadow: inset 0 3px 5px rgb(0 0 0 / 13%)!important;
  color: #51586a;
  background-color: #e6e6e6;
  border-color: #c7c7c7;
}

.btn-view-mode-active {
  box-shadow: inset 0 3px 5px rgb(0 0 0 / 13%)!important;
  color: #51586a !important;
  background-color: #e6e6e6 !important;
  border-color: #c7c7c7 !important;
}

.primary-submit-btn {
  background-color: $brand-primary!important;
  border-color: #1997c6!important;
  padding: 10px 16px!important;
  font-size: 18px!important;
  border-radius: 6px!important;
  width: 100%!important;

  &:hover {
    color: #fff!important;
    background-color: #1686b0!important;
    border-color: #126e90!important;
  }
}

.order-providers-list-group > div:hover {
  background-color: #252830!important;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: transparent!important;
  text-decoration: none;
  background-color: #434857!important;
}

.panel {
  &--scrollable {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    max-height: 410px;

    & .header-item {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 100;
      background-color: $body-bg;
    }

    @media screen and (max-width: 600px) {
      .list-group-item {
        min-width: 560px;
      }
    }
  }
}

.formLabelWarning {
  color: #ffc107;
}

.control-label {
  &--text-left {
    text-align: left !important;
  }
}

.s-alert-wrapper > .s-alert-box {
  z-index: 999999!important;
  font-weight: 400;
  letter-spacing: 0.5px;
}

.order-providers {
  margin-top: 10px;
}

.order-providers-item {
  .currency-addon {
    padding: 0px!important;
    border: none!important;
    border-right: 0!important;
    border-bottom-right-radius: 0!important;
    border-top-right-radius: 0!important;

    &[disabled] {
      background: transparent;
    }
  }

  .form-control {
    width: 132px;
    padding: 0 2px!important;
    margin: 0 auto!important;

    &.cost {
      width: 65px!important;
      border-bottom-left-radius: 0!important;
      border-top-left-radius: 0!important;
      border: transparent!important;
      height: 33px!important
    }

    &.currency {
      width: 50px!important;
      height: 33px!important;
      border: transparent!important;
      border-bottom-right-radius: 0!important;
      border-top-right-radius: 0!important;
    }

    &[disabled] {
      background: transparent;
      border-color: transparent;
      cursor: default;
      user-select: text;
    }
  }
}

.order-providers-form {
  padding: 10px 0 0;
}

.gauge {
  width: 100% !important;
  height: auto !important;
  max-width: 350px;
  margin: 0 auto;

  &-value {
    font-weight: 500;
    font-size: 23px;
  }

  &-percent {
    font-size: 20px;
    font-weight: 500;
  }
}

.tooltip {
  z-index: 10000000!important;
}

.tooltip-pointer {
  position: absolute;
  z-index: 99999;
  max-width: 200px;
  padding: 5px 12px;
  font-size: 12px;
  color: #333333;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 2px;
  pointer-events: none;
}

.pointer {
  cursor: pointer;
}

.text-indicator {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 120px;
  margin-bottom: 30px;

  @media (min-width: 768px) {
    min-height: 225px;
    margin: 0;
  }

  &__value-wrapper {
    text-align: center;
    margin: auto;
    transform: translateY(-20px);
  }

  &__valuetext {
    font-size: 25px;
    font-weight: 500;

    @media (min-width: 768px) {
      font-size: 2.3vw;
    }
  }

  &__subtext {
    min-height: 21px;
  }
}

.analytics-flex-row {
  display: flex;
  align-items: stretch;
  justify-content: space-around;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
  }
}

.asterisk {
  position: absolute;
  right: 35px;
  top: 50%;
  transform: translateY(calc(-50% - -4px));
  display: inline-block;
}

select.form-control {
  cursor: pointer;
}

.form-inline .form-control.inline-picky-wrapper {
  // Picky (inside SelectMultiple) has own paddings
  padding: 0!important;
  // Picky does not set width on it's own
  width: 180px;
  // Required to position `badge` properly
  position: relative;
}

.form-inline .form-control.inline-picky-wrapper-full {
  // Picky (inside SelectMultiple) has own paddings
  padding: 0!important;
  // Picky does not set width on it's own
  width: auto;
  min-width: 250px;
  max-width: 300px;
  // Required to position `badge` properly
  position: relative;
}

.badge-sm {
  font-size: 75%!important;
  border-radius: 0.25em;
}

.badge.bg-info {
  background-color: #9F86FF!important;
}

.badge.bg-success {
  background-color: #1BC98E!important;
}

.bg-default {
  background-color: #cfd2da!important;
}

.badge-danger {
  background-color: #E64759!important;
}

.outlined-badge {
  background-color: transparent !important;
  color: #007bff;
  border: 1px solid #007bff;
  border-radius: 1em;
  margin: 0 5px;
}

.outlined-badge-success {
  color: #1bc98e !important;
  border-color: #1bc98e !important;
}

.outlined-badge-warning {
  color: #FFA500 !important;
  border-color: #FFA500 !important;
}

.outlined-badge-danger {
  color: #dc3545 !important;
  border-color: #dc3545 !important;
}

.outlined-badge-info {
  color: #17a2b8 !important;
  border-color: #17a2b8 !important;
}

.outlined-badge-primary {
  color: #1CA8DD !important;
  border-color: #1CA8DD !important;
}

.picky {
  color: #51586a;

  .picky__input--disabled {
    opacity: .7;
    cursor: not-allowed!important;
  }

  .picky__input {
    border-radius: 4px;
    height: auto;
    padding-right: 12px;
    font-weight: 300;
  }

  .picky__placeholder {
    color: #fff;
    padding-left: 12px;
    padding-right: 12px;
    display: block;
    font-weight: 300;

  }

  .picky__dropdown {
    z-index: 1100;
    font-weight: 300;

  }

  .picky__dropdown input:not(:checked){
    background-color: #ffffff;
    border-width: 2px;
    font-weight: 300;

  }

  button {
    color: #fff;
    background-color: #434857;
    background-image: none;
    border: 1px solid #434857;

    &>span {
      padding-left: 12px;
    }
  }
}

// style picky component in the case when it's located inside a modal
.modal-dialog {
  .picky {
    color: #51586a;

    .picky__placeholder {
      color: #51586a;
    }

    button {
      color: inherit;
      background-color: $modal-content-bg;
      border: 1px solid $modal-content-border-color;
    }
  }
  .picky-spacer-wrapper {
    .picky {
      &__input span {
        padding-left: 6px;
      }
      &__dropdown {
        padding-left: 12px;
      }
    }
  }
}

.pickyNumber {
  position: absolute;
  background: rgb(221, 75, 60);
  color: #fff;
  z-index: 1;
  width: 20px;
  line-height: 20px;
  text-align: center;
  font-size: 10px;
  font-weight: bold;
  border-radius: 100%;
  right: 5px;
  top: -8px;
  transition: opacity 0.2s linear;

  &__hidden {
    color: transparent;
    opacity: 0;
  }
}

.picklistModal {
  position: fixed;
  padding: 15px;
  text-align: center;
  overflow: auto;
}

.picklist-p-title {
  font-weight: bold;
  color: #1caffd;
}

.pickList-p {
  font-weight: bold;
  color: black;
  font-size: 16px;
}

.picklist-well {
  text-align: center;
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: white;
  border: 2px solid white;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);

  .picklist-group-item {
    text-align: left;
    position: relative;
    display: block;
    padding: 10px 15px;
    margin-bottom: -1px;
    background: #232526;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #414345, #232526);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #414345, #232526);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    color: white;
    border: 2px solid white;
    font-weight: bold;
    word-wrap: break-word;

    &__disabled {
      opacity: 0.7;
    }

    .picklist-group-item-row {
      display: flex;
      align-items: center;
    }
  }
}

.render-column {
  position: relative;

  .printItemCount {
    position: absolute;
    right: 10px;
    top: 10px;
    background: #252830;
    color: #fff;
    font-size: 12px;
    padding: 0 10px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    font-weight: bold;
  }
}

.toggleTitle {
  vertical-align: top;
}

.buildPlateSelect {
  .picky {
    .picky__input {
      padding-top: 2px;
      overflow: hidden;
    }
  }
}

.toggleSlider {
  vertical-align: top;
  margin: 4px 10px 0;

  &.react-toggle--checked:hover {
    .react-toggle-track {
      background: #252830;
    }
  }

  .react-toggle-thumb {
    border-color: #252830;
  }

  .react-toggle-track {
    background: #252830;
  }
}

.toggleSliderDark {
  vertical-align: top;
  margin: 0px 10px 0;

  &.react-toggle--checked:hover {
    .react-toggle-track {
      background: #434857;
    }
  }

  .react-toggle-thumb {
    border-color: #252830;
  }

  .react-toggle-track {
    background: #434857;
  }

  &.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: #3d3f4a;
  }
}


.render-thumbnail {
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  max-width: 395px;
  max-height: 298px;

  button {
    height: 100%;
  }
}

.order-status-items {
  display: flex;
  align-items: center;

  @media (max-width: 992px) {
    flex-direction: column;
  }
}

.form-text {
  font-size: 14px!important;
}

.order-status-item {
  margin-right: 3rem;

  @media (max-width: 992px) {
    margin: 0.5rem 0;
  }
}

.order-summary-btn {
  @media (max-width: 992px) {
    margin: 0.5rem 0;
  }
}

.order-progress {
  position: relative;
  overflow: hidden;
  height: 21px;
  background-color: #ffffff;
  -webkit-box-shadow: inset 0 1px 2px rgb(0 0 0 / 10%);
  box-shadow: inset 0 1px 2px rgb(0 0 0 / 10%);
  color: #fff;

  .order-progress__bar {
    float: left;
    width: 0;
    height: 100%;
    font-size: 12px;
    line-height: 21px;
    text-align: center;
    background-color: #009F10;
    -webkit-box-shadow: inset 0 -1px 0 rgb(0 0 0 / 15%);
    box-shadow: inset 0 -1px 0 rgb(0 0 0 / 15%);
    -webkit-transition: width 0.6s ease;
    -o-transition: width 0.6s ease;
    transition: width 0.6s ease;
    &--complete {
      background-color: #999999;
    }
  }

  .order-progress__value {
    position: absolute;
    inset: 0;
    text-align: center;
  }
  .order-progress__icon {
    display: inline-block;
  }
}

.order-status-number-new {
  color: #f5f5f5;
  padding: 0.75rem;
  border-radius: 4px;
  display: inline-block;
  margin-right: 0.75rem;

  &.blue {
    background-color: #2c78e4;
  }

  &.orange {
    background-color: #ff9902;
  }

  .num {
    position: relative;
    align-items: center;
  }

  &.success {
    background-color: #5cb85c;
  }

  &.default {
    background-color: #99989A;
  }
}

// Remove `order-status-number` when not in use
.order-status-number {
  color: #f5f5f5;
  padding: 0.75rem;
  border-radius: 4px;
  display: inline-block;
  margin-right: 0.75rem;

  &.default {
    background-color: #99989A;
  }

  &.success {
    background-color: #5cb85c;
  }

  &.danger {
    background-color: #d9534f;
  }

  &.warning {
    background-color: #f0ad4e;
  }

  &.info {
    background-color: #5bc0de;
  }

  .sup {
    position: relative;
    top: -0.5em;
  }

  .slash {
    font-size: 1.4em;
    line-height: 1;
  }

  .sub {
    position: relative;
    bottom: -0.15em;
  }
}

.line-item-btn-row {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 15px;

  @media (max-width: 767px) {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 15px;
  }
}

.bl-light {
  border-left: 1px solid #e5e5e5;
  padding-left: 16px;
}

.panel-light {
  background-color: #ffffff;
  color: #555555;

  .panel-heading {
    color: #333;
  }

  .form-control[readonly] {
    background-color: #ffffff;
    color: #555555;
    border: 1px solid #cccccc;
  }

  .input-group-text {
    height: unset;
    padding: 6px 12px!important;
    background-color: #eeeeee;
    color: #555555;
    border: 1px solid #cccccc;

    &:last-child {
      border-left: 0;
    }
  }
}

.textarea-autocomplete-container {

}

.textarea-autocomplete-list {
  margin: 0;
  padding: 0;
  background: #fff;
  border-radius: 4px;
  list-style: none;
}

.textarea-autocomplete-item {
  border-bottom: 1px solid #434857;
}

.rta__entity {
  padding: 3px 12px;
  color: white;
  background: #3c4252;
  border: none !important;
}

.rta__entity--selected {
  color: #fff;
  text-decoration: none;
  background: #818aa6;
}

.rta__item:not(:last-child) {
  border: none;
}

.comments {
  .panel-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    outline: none;
  }


  .comment-sort-toggle-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 6px;
  }

  .comment-sort-toggle {
    &.react-toggle:hover {
      .react-toggle-track {
        background: #434857;
      }
    }

    .react-toggle-thumb {
      border-color: #434857;
    }

    .react-toggle-track {
      background: #434857;
    }
  }

  .panel-body {
    padding: 0;
  }

  .comment-item {
    display: flex;
    border-radius: 4px;
    padding: 6px;
  }

  .comment-item:hover {
    background-color: #434857;
  }

  .comment-item-name {
    font-weight: 900;
  }

  .comment-item-date,
  .comment-item-action {
    padding-left: 12px;
    font-size: 12px;
  }

  .tabs-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 12px;
    margin-top: 6px;

    .fa {
      font-size: 18px;
      margin-right: 12px;
    }
  }

  .nav-tabs {
    border-color: transparent;
  }

  .fa {
    padding: 6px;
  }

  .info-wrapper {
    display: flex;
    font-size: 12px;
    margin-top: 6px;
  }

  .char-count {
    margin-left: 12px;
  }

  textarea {
    min-height: 150px;
    height: auto;
    resize: vertical;
  }

  .preview-container {
    padding: 6px 12px;
    background-color: #434857;
    border-radius: 4px;
  }

  .no-preview {
    margin: 6px 0;
    font-style: italic;
  }

  .buttons {
    margin-top: 12px;
    display: flex;
    justify-content: flex-end;

    button {
      margin-left: 12px;
    }
  }
}

.modal .input-group-addon, .modal .form-control {
  color: #51586a!important;
  border-color: rgba(0, 0, 0, 0.2)!important;
  background: #fff!important;

  &:disabled {
    background-color: rgba(255, 255, 255, 0.2) !important;
    color: #adadad !important;
  }
}

.modal .nav-tabs .nav-link.active {
  border-color: #cfd2da!important;
  background-color: #cfd2da!important;
  color: #51586a!important;
}

.show {
  z-index: 100000 !important; // TODO investigate this and get rid of importants and big numbers
}

.fade.alert.alert-info.show{
  z-index: 1029 !important; //
}

.modal-backdrop.fade.show {
  z-index: 1070 !important;
}

.modal .nav-tabs .nav-link {
  color: #252830!important;

  &:hover {
    background-color: #cfd2da!important;
  }
}

.custom-tooltip, .tooltip .show {
  box-shadow: 0 0 4px 1px #00000057;
  padding: 0;
  z-index: 10000000 !important;
}

.modal .comments {
  .comment-sort-toggle {
    &.react-toggle:hover {
      .react-toggle-track {
        background-color: $gray-light;
      }
    }

    .react-toggle-thumb {
      border-color: $gray-light;
    }

    .react-toggle-track {
      background-color: $gray-light;
    }
  }

  .comment-item:hover {
    background-color: $gray-light;
  }

  .nav-tabs li.active a {
    border-color: $gray-light;
    background-color: $gray-light;
    color: $gray-dark;
  }

  .nav-tabs li a {
    color: $gray-base;

    &:hover {
      background-color: $gray-light;
    }
  }

  .preview-container {
    background-color: $gray-light;
    color: $gray-dark;
  }
}

.char-count-err {
  color: #e64759;
  font-weight: 900;
}

.initial-comment-limit {
  margin-top: 6px;
}

.markdown-styling {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 6px;
    margin-bottom: 6px;
    font-weight: 900;
  }

  p {
    margin: 0;
  }

  table {
    border: 1px solid #cfd2da;
    margin: 15px 0;
  }

  th,
  td {
    padding: 2px 6px;
    border-right: 1px solid #cfd2da;
  }

  th:last-of-type,
  td:last-of-type {
    border-right: none;
  }

  tr {
    border-bottom: 1px solid #cfd2da;
  }

  tr:last-of-type {
    border-bottom: none;
  }

  code {
    color: #9f86ff;
    background-color: #252830;
  }

  pre {
    color: #9f86ff;
    background-color: #252830;
    border: none;
    overflow-x: hidden;
  }
}

.shipment {
  .currency-addon {
    padding: 0px;
  }

  .form-control {
    &.currency {
      width: 75px;
      height: 33px;
    }
  }
}

.logo-heading-text {
  font-size: 24px!important;
  margin-top: 21px!important;
  margin-bottom: 10.5px!important;
}

.page-header-flex {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: stretch;
  }
}

.page-header-flex-title {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-right: 15px;
}

.workflow-preview-modal {

  overflow-y: auto;
  max-height: 85vh;

  .item-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid black;
  }

  .item-header {
    display: flex;
    justify-content: space-between;
  }

  .item-header-right-content {
    display: flex;
    align-items: center;

    span.fa {
      cursor: pointer;
    }
  }

  .item-header-right-content-labels {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .item-work-instructions {
    display: flex;
    justify-content: space-between;
    padding: 3px;
    padding-left: 30px;
  }

  .item-work-instructions:nth-child(odd) {
    background: #f2f2f2;
  }
}

input[type='file'],
input[type='file']::-webkit-file-upload-button {
  cursor: pointer;
}

.sketch-picker {
  color: #000;
}

.truncate-data {
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.wrap-text {
  word-break: break-word;
}

.sticker .only-printable {
  display: none;
}

.model-library-thumbnail {
  display: block;
  max-width: 100%;
  border-radius: 5px;
  overflow: hidden;

  &.column {
    max-width: 150px;
  }
}

.ellipsis-menu-icon {
  padding: 0 6px;
  cursor: pointer;
}

.pl-1 {
  padding-left: ($spacer-x * 0.5);
}

.pr-1 {
  padding-right: ($spacer-x * 0.5);
}

.crossed-line {
  text-decoration: line-through;
}

.product-change-information {
  font-size: 16px;

  .change-information-content-wrapper {
    max-height: 72px;
  }

  .right-chevron-icon {
    min-width: 26px;
  }

  .change-info-more-link, .change-info-expander  {
    color: white;
    text-decoration: underline;
    cursor: pointer;
    font-weight: bold;
  }

  .change-information-content {
    max-width: 380px;
    padding: 0 0 0 12px;
    height: 72px;
    overflow: auto;
  }
}

.manufacturabilityIconText {
  font-size: 20px;
  margin-left: 7px !important;
}

.truncate {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.analysisMethodPanel {
  display: flex;
  justify-content: space-between;
  padding: 5px;
  color: #000;

  &Link {
    &:hover,
    &:hover:active,
    &:hover:focus,
    &:focus,
    &:active {
      text-decoration: none;
    }
  }

  &Col {
    padding: 10px;

    &:first-child {
      flex-basis: 50%;
    }
  }

  &Status {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  &PrintTime {
    flex-basis: 32%;
  }

  &Alternative {
    max-width: 250px;
  }

  &CostRangeRow {
    display: flex;
    margin-bottom: 10px;
    justify-content: space-between;

    & > div > p:first-of-type {
      white-space: nowrap;
    }
  }
}

.QueuesStyle {

  &Overlay {
    width: 100%;
    max-width: 180px;
  }

  &QrContainer {
    text-align: center;
    display: flex;
    padding-top: 5px;
    align-items: center;
    justify-content: center;
  }

  &QrLink {
    color: #19A8DD;
    font-size: 16px;
    margin: 2px 5px;
    transition: .5s;
    z-index: 999;
  }

  &QrLink:hover {
    transform: scale(1.1);
  }
}

.page-header.post-processor-header {
  border-bottom: 1px solid #ddd;
  width: calc(100vw - 132px);
  margin-bottom: 16px;
}

.with-max-content {
  width: max-content !important;
}

.chip-note {
  display: inline-block;
  padding: 8px;
  border-radius: 6px;
}

.nc-piece-list {
  border: 1px solid #cfd2da;
  border-radius: 4px;
  margin: 5px 0;
  height: 250px;
  overflow-y: auto;
}

.nc-piece {
  margin: 5px;
  border: 1px solid #cfd2da;
  border-radius: 4px;
  padding: 2px;
  padding-left: 5px;
  padding-right: 5px;
  word-break: break-word;
}

.ganttTimeline > .rct-outer > .rct-sidebar > div >.rct-sidebar-row   {
  white-space: pre-wrap;
  line-height: 25px!important;
  display: flex;
  align-items: center;
}

.ganttTimeline > .rct-outer > .rct-sidebar {
  transition: .5s;
}

.ganttTimeline > .rct-header-root > div {
  transition: .5s;
}

.ganttTimeline > .rct-outer > .rct-sidebar > div > .rct-sidebar-row, .rct-sidebar-row-even, .rct-sidebar-row-odd {
  transition: .5s;
}

.orderGanttChartStatuses {
  color: #000;
  margin-left: 10px;
}

.highlightWrongUri {
  box-shadow: 0 0 5px 1px #e4d837;
}

.restrictedTitleWidth {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.restrictedTitleWidthPieces {
  color: #fff!important;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.formControlSelect {
  background-color: #fff!important;
}

.show > .dropdown-toggle {
  border: none;
  background: none;
  color: #51586A;
}

.modal.show {
  color: #51586a!important;
}

.dropdown-toggle {
  background: none;
  color: #51586A;
  &:hover {
    color: #51583A;
    background: none;
  }
  &::after  {
    display: inline-block;
    position: unset!important;
    width: 0!important;
    height: 0!important;
    margin-left: 2px;
    vertical-align: middle!important;
    border-top: 4px dashed!important;
    border-top: 4px solid \9;
    border-right: 4px solid transparent!important;
    border-left: 4px solid transparent!important;

  }
  &:focus {
    background: none;
    border: none;
    box-shadow: unset;
  }
}

.badge-pull-right {
  left: 100%;
  position: relative;
}

.selectMaterialWarning {
  border: 1px solid #FEC00B;
  border-radius: 5px;
}

.work-instructions-step-action-btn {
  background: #434857;
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
  padding: 10px;
  border-radius: 6px;
  margin: 10px 0;
  width: 100%;
  transition: .3s;
  cursor: pointer;
}

.modal .work-instructions-step-action-btn {
  background: transparent;
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
}

.work-instructions-step-action-btn:hover {
  background: #1DA8DD;
  color: #fff;
  border: 1px solid #1DA8DD !important;
}

.work-instructions-step-action-btn[disabled] {
  color: grey;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  cursor: not-allowed;
}

.work-instructions-step-action-btn[disabled] .work-instructions-step-action-icon {
  fill: grey;
}

.work-instructions-step-action-icon {
  transition: .3s;
  width: 35px;
  height: 35px;
  fill: #d0d2da;
}

.modal .work-instructions-step-action-icon {
  fill: #000;
}

.work-instructions-step-action-btn:hover .work-instructions-step-action-icon {
  fill: #fff;
}

.work-instructions-step-action-btn:hover .work-instructions-step-action-text {
  color: #fff;
}

.work-instructions-step-action-btn[disabled]:hover {
  background: #434857;
  color: grey;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.modal .work-instructions-step-action-btn[disabled]:hover {
  background: transparent;
}

.work-instructions-step-action-btn[disabled]:hover .work-instructions-step-action-icon {
  fill: grey;
}

.modal .work-instructions-step-action-btn-active {
  background: #1DA8DD;
  color: #fff;
  border: 1px solid #1DA8DD !important;
}

.work-instructions-step-action-btn-active {
  background: #1DA8DD;
  color: #fff;
  border: 1px solid #1DA8DD !important;
}

.work-instructions-step-action-btn-active .work-instructions-step-action-icon {
  fill: #fff;
}

.modal .work-instructions-step-action-btn-active .work-instructions-step-action-icon {
  fill: #fff;
}

.work-instructions-step-action-hr {
  border-color: #000;
  margin: 10px 0;
}

.work-instructions-step-action-title {
  margin: 15px 0 5px !important
}

.work-instructions-step-action-text {
  color: #d0d2da;
  transition: .3s;
  white-space: nowrap;
  margin: 0 !important;
}

.modal .work-instructions-step-action-btn-active .work-instructions-step-action-text {
  color: #fff;
}

.work-instructions-step-action-btn-active .work-instructions-step-action-text {
  color: #fff;
}

.modal .work-instructions-step-action-text {
  color: #000;
}

.work-instructions-step-action-btn[disabled]:hover .work-instructions-step-action-text {
  color: grey;
}

.work-instructions-step-action-btn[disabled]:hover .work-instructions-step-action-text {
  color: grey;
}

.work-instructions-step-action-btn[disabled] .work-instructions-step-action-text {
  color: grey;
}

.laborTimeInputText {
  padding: 7px 15px !important;
  border: none !important;
  border-left: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;

  &Addition {
    font-size: 14px;
    font-weight: bold;
  }

  &Disabled {
    background-color: #424654 !important;
    color: #8E9098!important;
    cursor: not-allowed!important;
  }
}

.laborTimeInput {
  padding: 7px !important;
  border: none !important;
  border-right: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
  width: 135px;
}

.laborWeekdays {
  width: 110px !important;
  text-align: end;
  margin: 0 !important;
  font-size: 16px;
  font-weight: 500;
  margin-right: 25px!important;
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.laborWorkdayCheckbox {
  margin-left: 15px;

  & > label  {
    font-size: 16px;
  }
}

.removeLaborData,
.removeMultiMaterial,
.removeintegratedFlag {
  color: #DE4B3B;
}



.user-managed-helper-text {
  font-size: 12px;
  text-align: center;
  margin-bottom: 5px!important;
  font-style: italic;
}

.disabled-interaction {
  pointer-events: none;
  opacity: 0.5;
}

.half-opacity {
  opacity: 0.5!important;
}

.not-allowed-cursor {
  cursor: not-allowed!important;
}

.wip_matrix_table tbody {
  overflow-y: scroll;
  height: 100%;
  max-height: calc(70vh - 45px);
  display: block;
}

.wip_matrix_sort > #picky__button__button {
  min-height: 35px;
  font-size: 14px;
  font-weight: 300;
}

.wip-matrix-sticky-header {
  position: sticky;
  left: 0;
}

.wip_matrix_table thead, .wip_matrix_table tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.wip_matrix_table_expanded thead th {
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.wip_matrix_table_expanded thead th:first-child {
  width: 250px;
}

.wip_matrix_table_expanded tbody td {
  width: 100px;
}

.infinite-scroll-component > tr > a > td > span  {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 145px;
  white-space: nowrap;
}

#matrixScrollableArea > tr > a > td {
  max-width: 100%!important;
}

.wip_matrix_container {
  overflow-x: auto;
}

.wip_matrix_table tbody {
  display: block;
  overflow-y: overlay;
  table-layout: fixed;
}

.wip_matrix_card > span {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100rem;
  white-space: nowrap;
  display: block;
}

table {
  max-height: 70vh;
  overflow: hidden;
}

#matrixScrollableArea > tr {
  white-space: nowrap;
}

.wip-matrix-indicator {
  height: 25px;
  width: 25px;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wip-matrix-indicator > span {
  color: white;
  font-weight: bold;
}

.wip-matrix-order-col-fixed {
  position: relative;
  z-index: 50;
  background-color: $gray-base!important;
  outline: 2px solid #434857;
}


/* Customize website's scrollbar like Mac OS
Not supports in Firefox and IE */

/* total width */
#matrixScrollableArea::-webkit-scrollbar {
  background-color: #fff;
  width: 14px;
}

/* background of the scrollbar except button or resizer */
#matrixScrollableArea::-webkit-scrollbar-track {
  background-color: #fff;
}

/* scrollbar itself */
#matrixScrollableArea::-webkit-scrollbar-thumb {
  background-color: #858592;
  border-radius: 16px;
  border: 4px solid #fff;
}

/* set button(top and bottom of the scrollbar) */
#matrixScrollableArea::-webkit-scrollbar-button {
  display:none;
}

.spinner-centered-white {
  color: white;
  display: flex!important;
  justify-content: center;
}

.material-test-list-group-item {
  margin-top: 3px;
  padding: 8px;
  border-radius: 4px;
}

.material-test-list-group-item:hover {
  cursor: pointer;
  color: white;
  background-color: #1CA8DD;
}

.material-test-list-group-item.selected {
  color: white;
  background-color: #1CA8DD;
}

.modal-backdrop.fade.show.priorityBackdrop {
  z-index: 100000!important;
}

.subLocationsTruncatedName {
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.break-word {
  word-break: break-word!important;
}

.readOnly:hover {
  cursor: default !important;
}

.scrollableUserList {
  max-height: 400px;
  overflow-y: auto;
}

.stickyHeader {
  position: sticky;
  top: 0;
  z-index: 1;
}

.tooltipDefaultWidth {
  width: 100%;
  max-width: 250px;
}

.workScheduleTooltip {
  width: 260px;
  max-width: 280px;
  background-color: #30343E;
  color: #fff;
  padding: 10px 15px;

  &Title {
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 5px;

    &Timezone {
      text-align: center;
      color: #d5d5d5;
      margin-bottom: 5px;
    }
  }
}

.workScheduleTooltipTime {
  margin-bottom: 0 !important;
  color: #fff;
  font-size: 14px;
}

.workScheduleTooltipTimeOff {
  margin-bottom: 0 !important;
  color: #fff;
  font-size: 12px;
}

.workScheduleDays {
  width: 85px !important;
  text-align: start;
  margin: 0 !important;
  font-size: 14px;
  font-weight: 500;
  margin-right: 15px!important;
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ddwModelLibraryLabel {
  font-size: 10px;
  text-transform: uppercase;
  padding: 20px 10px 10px 10px;
  color: #b8b2b2;
  letter-spacing: 2px;
}

.workflowProcessStepsLoader {
  position: absolute;
  left: 50%;
  margin-top: 20px;
  border-top: none !important;
}

.powder-custom-field-item {
  display: flex;
  justify-content: space-between;
  font-weight: 400;
  margin: 0 10px 0 0 !important;
  max-width: 90px;
  min-width: 90px;
  padding: 0 !important;
}

#managerManageShoppingCartsView > li {
  list-style-type: none;
}

.run-new-control-area {
  background-color: rgb(48, 52, 62);
  padding: 9px 10px;
  border-radius: 3px;
  margin-bottom: 20px;
}

.run-new-printer-input-highlighted {
  box-shadow: 0 0 4px 2px #ffffff47;
  border-radius: 4px;
  transition: 0.3s;

  &:hover {
    box-shadow: 0 0 4px 2px rgba(255, 255, 255, 0.07);
  }
}

.run-new-printer-input {
  min-width: 280px;
  max-width: 100%;
  margin-right: 20px;
}

.run-new-drag-n-drop-highlight {
  background-color: #1CA8DD !important;
  color: #fff !important;

  tr a {
    color: #fff !important;
  }
}

.add-carousel-image-btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  max-width: 100px;
  min-width: 100px;
  height: 100px;
  border-radius: 12px;
  border: 2px solid rgb(127 127 127 / 24%);
  transition: .2s;
}

.add-carousel-image-icon {
  color: rgb(127 127 127 / 82%);
  transition: .2s;
}

.add-carousel-image-btn:hover,
.add-carousel-image-btn:hover .add-carousel-image-icon {
  color: rgb(28, 168, 221);
  border-color:  rgb(28, 168, 221);
}

.barcode {
  margin: 1em auto;
  & > a > svg {
    width: 500px !important;
    background: #fff;
  }
}

.material-batch-card-details {
  border-radius: 5px 5px 0 0;
  border: 1px solid #F5F5F5;
  padding: 24px;
}

.material-batch-card-details-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}

.material-batch-card-details-body {
  background-color: #2D3138;
  padding: 16px 24px;
}

.powder_quality_badge {
  cursor: pointer;
  padding: 3px 5px;
  margin-left: 5px;
  border-radius: 17px;
  background: #D0D2D9;
  color: #222529;
  font-size: 12px;
  font-weight: 500;
}

.material-batch-card-details-header-status {
  display: flex;
  align-items: center;
  justify-content: center;
}

.truncated-items-tooltip-overlay {
  width: 100%;
  max-width: 600px;
  margin-bottom: 0;
  word-break: break-word;
}

.progress-bar-upload-file {
  animation-name: fadeInUp;
  animation-duration: 1s;
  animation-fill-mode: both;
  position: fixed;
  z-index: 99999999;
  right: 5%;
  bottom: 0;
  width: 450px;
  background-color: #3a3d46;
  color: #fff;
  border-radius: 7px 7px 0 0;
  box-shadow: 0 0 2px 1px rgb(18 19 24 / 40%);
  padding-bottom: 1px;
}

.progress-bar-upload-file-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  color: #fff;
  transition: all 0.3s;
  position: relative;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  padding: 0;
}

.progress-bar-upload-file-button:hover {
  background-color: #3b3d46;
}

.progress-bar-upload-file-header {
  display: flex;
  justify-content: space-between;
  background-color: #434857;
  padding: 8px;
  border-radius: 7px 7px 0 0;
  align-items: center;
}

.progress-bar-upload-file-header-modal {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
}

.progress-bar-upload-file-header > p, .progress-bar-upload-file-header-modal > p {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0;
}

.progress-bar-upload-file-estimation {
  font-size: 10px;
  color: #AEAEAE;
  margin-bottom: 0;
}

.progress-bar-upload-file-item {
  transition: all 0.3s;
}

.progress-bar-upload-file-item:hover {
  cursor: pointer;
  background-color: #4D5261;
}

.progress-bar-upload-file-body {
  max-height: 210px;
  overflow-y: scroll;
}

.progress-bar-upload-file-data {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
}

@keyframes fadeInUp {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.nav-sub-menu-trigger {
  position: relative;
  padding: 5px 8px;
  border-radius: 5px;
  display: inline-block;
  vertical-align: middle;
  transition: .3s all;
}

.nav-sub-menu-trigger:hover {
  background-color: #f5f5f5;
}

.nav-sub-menu-arrow {
  cursor: pointer;
  color: rgb(97 97 97);
  font-size: 17px;
}

.nav-sub-menu-item {
  position: absolute;
  top: calc(100% - 30px);
  left: calc(100% - 8px);
  z-index: 1000;
  min-width: 160px;
  padding: 5px 0;
  list-style: none;
  font-size: 14px;
  text-align: left;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.175);
  white-space: nowrap;
}

.nav-sub-menu-item .active {
  color: #fff;
  text-decoration: none;
  background-color: #337ab7;
}

.custom-navbar-icon path {
  fill: black;
}

.custom-navbar-icon-white path {
  fill: white;
}

.printer-type-icon svg path {
  fill: #1ca8dd;
  transition: fill 0.3s;
}

.printer-type-icon:hover svg path {
  fill: #23527c;
}

.printer-type-resource-icon {
  margin-right: 2px;
  width: 18px;
}

.printer-type-resource-icon path {
  fill: #d0d2da;
}

.printer-types-for-materials {
  margin-right: 2px;
}

.p-10 {
  padding: 10px;
}

.printer-types-for-materials path {
  fill: #d0d2da;
}

.dropdown-item.active .custom-navbar-icon path,
.dropdown-item:active .custom-navbar-icon path {
  fill: #fff;
}

.form-validation-message {
  position: absolute;
  bottom: -20px;
  left: 0;
  right: 0;
  font-size: 0.875em;
  font-weight: 600;
  color: #dc3545;
}

.no-scrollbar::-webkit-scrollbar {
  width: 3px;
}

.no-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}

.no-scrollbar::-webkit-scrollbar-thumb {
  background: $gray;
  border-radius: 5px;
}

.rc-tooltip {
  z-index: 10000000 !important; // make sure it's on top of everything (especially modal window)
}

.users-list-locations-tooltip {
  z-index: 99999999!important;
}

.printerTypeBufferTitle {
  color: #fff !important;
  font-size: 17px;
  font-weight: 300;
  margin-bottom: 10px;
}

.buildPlateVolumeMeasurementInfo {
  text-align: right;
  font-weight: 300;
  font-size: 12px;
}

.darkTooltip {
  background-color: #30343E;
  color: #fff;
  padding: 10px;
  border-radius: 4px;
  max-width: 300px;
  word-wrap: break-word;
}

.materialTooltip {
  background-color: #30343E;
  color: #fff;
  padding: 10px;
  border-radius: 4px;
  max-width: 500px;
  word-wrap: break-word;
}

.materialPrinterTypeTooltipTitle {
  font-size: 14px;
  font-weight: 300;
}

.greyTooltip {
  background-color: #697080;
  color: #fff;
  padding: 10px;
  border-radius: 4px;
  max-width: 300px;
}

.permanent-container-material-option {
  border-radius: 3px;
  padding: 3px;
  margin-bottom: 2px;
}

.value-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  overflow: hidden; /* If you want to hide the overflow, or 'visible' to expand */
}

.customMultiSelectMorePlaceholder {
  cursor: pointer;
  margin-left: auto;
  font-weight: 500;
  color: #bebebe;
}


.customMultiSelectExpandAllBtn {
  background-color: #6a7179 !important;
  border-color: #ffffff !important;
  box-shadow: 0 0 0 0.25rem rgb(255 255 255 / 21%) !important;
  transition: 0.3s;
  &:hover {
    background-color: #43a0c3 !important;
    border-color: #20c4ce !important;
    box-shadow: 0 0 0 0.25rem #84a3af !important;
  }
}

.customMultiSelectCollapseBtn {
  background-color: #43a0c3 !important;
  border-color: #20c4ce !important;
  box-shadow: 0 0 0 0.25rem #43a0c3 !important;
  transition: 0.3s;
  &:hover {
    background-color: #697b81 !important;
    border-color: #def1f2 !important;
    box-shadow: 0 0 0 0.25rem rgb(192 192 192 / 50%) !important;
  }
}

.customMultiSelectCardTitle {
  line-height: 1.6 !important;
}

.impersonationModalText {
  color: #1ca8dd;
  transition: .2s;

  &:hover {
    color: #23527c;
    text-decoration: underline;
  }
}

.toast-action-button {
  background: none;
  border: none;
  padding: 0;
  margin-top: 3px;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  transition: .3s;
  box-shadow: unset !important;
}

.toast-action-button:hover {
  color: rgba(255, 255, 255, 0.84);
}

.bureau-notification {
  background-color: $gray-base;

  .btn-close {
    color: #fff;
  }
}

.bureau-notification-list-item {
  border-radius: 12px;
  background-color: lighten($gray-base, 5%) !important;
}

.users-list {

  &-roles-badge {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
  }

  &-edit-button {
    opacity: 0;
    background: none;
    border: none;
    padding: 0;
    margin-left: 5px;
    color: #fff;
    font-size: 12px;
    font-weight: 600;
    transition: .3s all ease-in-out;
    box-shadow: unset !important;

    &:hover {
      color: #fff;
    }

    & > svg {
      font-size: 16px;
    }
  }

  &-locations {
    position: relative;

    &-title {
      background: none;
      border: none;
      padding: 0;
      margin-left: 5px;
      cursor: default !important;
      color: #fff;
      font-size: 12px;
      font-weight: 600;
      box-shadow: unset !important;

      &:hover {
        color: #fff;
      }
    }

    &-button {
      background: none;
      border: none;
      padding: 0;
      margin-left: 5px;
      color: #19A8DD;
      font-size: 12px;
      font-weight: 600;
      transition: .3s;
      box-shadow: unset !important;

      &:hover {
        color: #1c84ab;
      }
    }

    &-badge {
      color: #fff;
      position: absolute;
      background-color: #DC3545;
      border-radius: 50%;
      font-size: 9px;
      font-weight: 600;
      padding: 3px;
      top: -9px;
      right: -4px;
      min-width: 18px;
      text-align: center;
    }

    &-icon {
      color: #fff;
      font-size: 24px;
    }
  }

  &-item {
    margin: 5px 0;
    border-top: 0;
    transition: .2s all ease-in-out;
    &:last-child {
      border-bottom: 0;
    }
    &:hover {
      cursor: pointer!important;
      color: #34856b04;
    }
  }
  &-header {
    background-color: #434856;
    padding: 5px 25px;
    color: #fff;

    & > p {
      margin-bottom: 0;
      font-weight: 500;
    }
  }

  &-empty {
    display: flex;
    align-content: center;
    justify-content: center;

    & > p {
      font-size: 24px;
      font-weight: 300;
    }
  }
}

.users-list-item:hover .users-list-edit-button {
  opacity: 1;
}

.users-list-item:hover .profile-avatar {
  border: 1px solid #FFA500;
}

.users-list-item.user-list-item--read-only:hover .profile-avatar {
  border: 1px solid #19A4D7;
}

.list-group-item.user-list-item--read-only:hover {
  background-color: unset;
}

.user-list-item--read-only {
  pointer-events: none;
}

.user-manage-modal {
  &-create-user {
    font-weight: 300;
    font-size: 24px;
    text-align: center;
    background-color: #434856;
    padding: 30px;
    margin-top: 55px;
    width: 100%;
  }

  &-body {
    padding-bottom: 50px;
    overflow: visible!important;
  }

  &-edit-button {
    padding: 3px 10px !important;
  }

  &-button {
    border: 1px solid #fff;
    padding: 5px 15px;
    border-radius: 4px;
    color: #fff;
    font-size: 14px;
    transition: all .2s ease-in-out;

    &-delete {
      margin: 0;
      box-shadow: none;
      background-color: unset;
      color: #dc3545;
      border: 1px solid #dc3545;
      padding: 5px 15px;
      border-radius: 4px;
      font-size: 14px;
      transition: all 0.2s ease-in-out;

      &:hover {
        color: #fff;
        background-color: #dc3545;
      }
    }

    &:hover {
      color: #000;
      background-color: #fff;
    }

    &-filled {
      background-color: #1bc98e;
      color: #fff;
      padding: 5px 15px;
      border-radius: 4px;
      font-size: 14px;
      transition: all .2s ease-in-out;

      &:hover {
        background-color: #1e9d73;
        color: #fff;
      }
    }
  }

  &-profile-avatar {
    position: absolute;
    top: -30px;
    left: 50%;
    transform: translate(-50%, 0);
    min-width: 70px;
    max-width: 70px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;

    &::before {
      content: "";
      border: 6px solid #2e303b;
      width: calc(100% + 16px);
      height: calc(100% + 16px);
      position: absolute;
      border-radius: 50%;
      top: -8px;
      left: -8px;
    }

    & > span {
      font-size: 30px !important;
      font-weight: 500 !important;
    }
  }

  &-header {
    position: relative;
    padding: 0 !important;

    &-icon {
      display: flex !important;
      align-items: center;
      justify-content: flex-end;
      position: absolute !important;
      right: 20px;
      top: 20px;
    }
  }

  &-info {
    background-color: #434856;
    padding: 30px;
    margin-top: 55px;
    width: 100%;
    position: relative;

    &-content {
      text-align: center;
      margin-top: 15px;
    }

    &-name {
      word-wrap: break-word;
      font-size: 24px;
      font-weight: 600;
      max-width: 100%;
    }

    &-email {
      word-wrap: break-word;
      font-size: 16px;
      font-weight: 100;
    }
  }
}

.user-manage-modal .modal-content {
  overflow: visible !important;
}

.user-manage-modal .modal-dialog .input-group-addon:disabled,
.user-manage-modal .modal-dialog .form-control:disabled {
  background-color: #434856 !important;
  color: #fff !important;
}

.user-modal .input-group-addon:disabled,
.user-modal .form-control:disabled {
  background-color: #30343e !important;
  color: #fff !important;
}


.user-modal .modal-dialog .picky button {
  background-color: #434856 !important;
  color: #fff !important;
}

.user-modal .modal-dialog .picky .picky__placeholder {
  color: #fff !important;
}

.location-modal .modal-content {
  overflow: visible !important;
}

.location-modal-body {
  overflow: visible !important;
}

.location-modal .location-modal-tabs .nav-link.active {
  color: #fff !important;
  background-color: #434857 !important;
  border-radius: 21px !important;
  border: 1px solid transparent !important;
  outline: none !important;
  isolation: unset!important;
}

.location-modal-tabs .nav-link:hover,
.location-modal-tabs .nav-link:focus {
  color: #e8e7e7 !important;
  background-color: unset !important;
  border-radius: 21px !important;
  border: 1px solid transparent !important;
  isolation: unset!important;
  outline: none!important;
}

.location-modal-tabs .nav-link:hover,
.location-modal-tabs .nav-link:focus {
  border-radius: 21px !important;
  border: 1px solid transparent !important;
}

.location-modal .location-modal-tabs .nav-link:hover {
  background-color: #434857 !important;
  border: 1px solid transparent !important;
  outline: none !important;
  isolation: unset!important;
  border-radius: 21px !important;
}

.location-modal .location-modal-tabs .nav-link {
  color: #e8e7e7 !important;
  border-radius: 21px !important;
}

.location-modal .location-modal-tabs .nav-link:disabled {
  color: #7a7676 !important;
}

.location-modal-dialog {
  max-height: 615px !important;
}

.location-modal-dialog > .modal-content {
  height: 100% !important;
}

@media (max-width: 991px) {
  .location-modal-dialog {
    max-height: unset !important;
  }
  .location-modal-dialog > .modal-content {
    height: unset !important;
  }
}

.location-modal-sub-location-tabs {
  margin-bottom: 30px;
}

.location-modal .location-modal-sub-location-tabs .nav-link {
  border-radius: 3px 3px 0 0 !important;
}

.location-modal .location-modal-sub-location-tabs .nav-link:hover {
  border-radius: 3px 3px 0 0 !important;
}

.location-modal-sub-location-tabs .nav-link:hover,
.location-modal-sub-location-tabs .nav-link:focus {
  border-radius: 3px 3px 0 0 !important;
}

.location-modal-sub-location-tabs .nav-link:hover,
.location-modal-sub-location-tabs .nav-link:focus {
  border-radius: 3px 3px 0 0 !important;
}

.location-modal .location-modal-sub-location-tabs .nav-link.active {
  border-radius: 3px 3px 0 0 !important;
}

.location-modal-tabs {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  padding-bottom: 0;
  padding-left: 25px;
  padding-right: 25px;
}

.location-modal-sub-location-tabs > .nav-tabs {
  border-bottom: 1px solid #D0D2DA !important;
  width: 100% !important;
}

.location-modal-sub-location-tabs > .nav-tabs .nav-item {
  margin-bottom: 7px !important;
}

.location-modal-tabs-sub-location-add-btn {
  color: #fff !important;
  border: unset;
  box-shadow: unset;
  outline: unset;

  &:hover {
    color: #bfbfbf !important;
  }
}

.sub-location-card {
  display: flex;
  background-color: #3B3F4B;
  border: 1px solid #434857;
  border-radius: 6px;
  padding: 15px;
  color: #fff;

  &-header {
    &-name {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 0;
    }

    &-uuid {
      font-size: 12px;
      font-weight: 300;
      color: #B2B2B2;
      margin-bottom: 0;
    }
  }

  &-icon {
    margin-right: 10px;
  }

  &-body {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-resources {
      display: flex;
      align-items: center;
      background-color: #434857;
      padding: 5px 15px;
      border-radius: 4px;

      &-item-link {
        margin-left: 5px;
      }

      &-item:first-child {
        margin-right: 15px;
      }
    }

    &-item {
      display: flex;
      justify-content: space-between;

      &:first-child {
        margin-bottom: 15px;
      }
    }

    &-rest-info-item:first-child {
      margin-bottom: 5px;
    }
  }

}

.action-icon-button {
  background: none;
  border: none;
  padding: 0;
  margin-top: 3px;
  margin-right: 10px;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  box-shadow: unset !important;

  & > svg {
    transition: .3s;
  }

  &:hover > svg {
    color: rgba(255, 255, 255, 0.84);
  }

  &:last-child {
    margin-right: 0;
  }
}

.Toastify__toast-container {
  // Should be over everything (especially modals)
  z-index: 999999999999999999 !important;
}

.location-resources {
  margin: 25px;
}

.resource-list-item-card {
  box-shadow: none !important;
  border: none !important;

  &-item {
    color: #fff !important;
    background-color: #2e303b !important;
    transition: .3s;
    border-bottom: 1px solid #434857 !important;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;

    &:hover {
      background-color: #434857 !important;
    }

    &-last {
      color: #fff !important;
      border: none !important;
      padding-top: 20px;
      font-weight: 500;
      background-color: unset;

      &:hover {
        background-color: unset !important;
      }
    }
  }

  &-header {
    color: #fff !important;
    background-color: #434857 !important;
    padding: 10px 15px;
    border-radius: 3px;
  }
}

.btn-success-dark {
  color: #1AC98E !important;
  border-color: #1AC98E !important;

  &:hover {
    color: #fff !important;
    background-color: #1AC98E !important;
    border-color: #1AC98E !important;
  }
}

.btn-info-dark {
  color: #9F86FF !important;
  border-color: #9F86FF !important;

  &:hover {
    color: #fff !important;
    background-color: #9F86FF !important;
    border-color: #9F86FF !important;
  }
}

.react-phone-input {
  font-size: 14px !important;
  line-height: 21px !important;
  height: auto;
  padding: 6px 16px 6px 48px !important;
  width: 100% !important;

  &__dropdown {
    border: none;
    color: #fff;
    background-color: #2E303B !important;
  }

  &__container {
    background-color: #434857 !important;
    padding: 0;
    border-radius: 7px !important;
  }
}

.react-tel-input .country-list .country:hover {
  background-color: #434857 !important;
}

.react-tel-input .country-list .country.highlight {
  background-color: #5e6577 !important;
}

.react-tel-input .flag-dropdown {
  background-color: unset !important;
  border: none !important;
}

.react-tel-input .selected-flag:hover, .react-tel-input .selected-flag:focus {
  background-color: #61646f !important;
}

.react-tel-input .selected-flag .arrow {
  border-top: 4px solid #fff !important;
}

.react-tel-input .country-list .country .dial-code {
  color: #aaaaaa !important;
}

.react-tel-input .flag-dropdown.open .selected-flag {
  background: #61646f !important;
}

.react-tel-input .selected-flag .arrow.up {
  border-top: none !important;
  border-bottom: 4px solid #fff !important;
}

.snapshotThumbnailPiecesCount {
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: #000;
  border-radius: 0 0 0 4px;
  font-size: 14px;
  font-weight: 600;
}

.outlined-checkbox .form-check-input {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid #9da3b7; /* Adjust the border color */
  border-radius: 4px; /* Adjust the border radius */
  background-color: #282a36; /* Adjust the background color */
  cursor: pointer;
}

.outlined-checkbox .form-check-input:checked {
  background-color: #3c58e2 !important;
  border: 2px solid #434857 !important;
}

.outlined-checkbox .form-check-input:disabled {
  border: 2px solid #67686b !important;
}

.hide-default-input-arrows::-webkit-outer-spin-button,
.hide-default-input-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.custom-info-alert {
  background-color: #19A8DD;
  color: #fff;
  max-width: 650px;
  padding: 10px 15px;
  border: unset;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
}

.modal-backdrop.show.incompatible-pieces-backdrop {
  z-index: 100001 !important;
}

.incompatible-pieces-modal {
  z-index: 999999!important;

  .modal-dialog.modal-sm {
    width: 400px!important;
  }

  @media (min-width: 576px) {
    .modal-dialog.modal-sm {
      max-width: 400px !important;
    }
  }

  .modal-header {
    position: sticky;
    top: 0;
    z-index: 999;
    margin-bottom: 0 !important;
    padding-bottom: 10px !important;
  }

  .modal-content {
    height: max-content !important;
    max-height: 600px !important;
    min-width: 400px !important;
    overflow-y: auto;
  }

  .modal-body {
    padding-top: 0 !important;
  }

  .modal-title:after {
    display: none !important;
  }
}

.hide-default-input-arrows {
  -moz-appearance: textfield; // Firefox
  -webkit-appearance: none; // Chrome, Safari, Edge
  appearance: none;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
  }

  &::-ms-clear {
    display: none;
  }

  &:hover {
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      appearance: none;
      margin: 0;
    }
  }
}

.checkmarkContainer {
  display: block;
  width: 76.25px;
  height: 69.25px;
}

.checkmarkWrapper {
  width: 100%;
  height: 100%;
}

.svgContainer {
  width: 76.25px;
  height: 69.25px;
  top: 0;
  left: 0;
}

.tickGreen,
.tickYellow,
.tickGray {
  position: absolute;
  fill: none;
  stroke-width: 2.5;
  stroke-linecap: round;
  stroke-miterlimit: 10;
  stroke-dasharray: 152;
  stroke-dashoffset: 0;
  animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  animation-fill-mode: both;
}

.tickGreen {
  stroke: #4ab71a;
  animation-name: tickGreenAnimation;
  animation-duration: 290ms;
  animation-delay: 868ms;
}

.tickYellow {
  stroke: #fb0;
  animation-name: tickYellowAnimation;
  animation-duration: 292ms;
  animation-delay: 637ms;
}

.tickGray {
  stroke: #ececec;
  animation-name: tickGrayAnimation;
  animation-duration: 276ms;
  animation-delay: 537ms;
}

.circleGray,
.circleLightBlue,
.circleGreen {
  position: absolute;
  fill: none;
  stroke-width: 2.5;
  stroke-linecap: round;
  stroke-miterlimit: 10;
  transform-origin: 69px 69px;
  stroke-dasharray: 337.5;
  animation-duration: 517ms, 529ms;
  animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  animation-fill-mode: both, both;
}

.circleGreen {
  stroke: #4ab71a;
  animation-name: rotateCircleDarkBlue, dashCircleDarkBlue;
  animation-delay: 270ms, 278ms;
}

.circleLightBlue {
  stroke: #fb0;
  animation-name: rotateCircleLightBlue, dashCircleLightBlue;
  animation-delay: 159ms, 157ms;
}

.circleGray {
  stroke: #ececec;
  animation-name: rotateCircleGray, dashCircleGray;
  animation-delay: 58ms, 58ms;
}

.your-role-ui-title {
  font-size: 12px;
  font-weight: 300;
  color: #EDEDED;
}

.your-role-ui-container {
  display: flex;
  align-items: center;
  background-color: #434856;
  padding: 3px 15px;
  border-radius: 15px 0 0 15px;
}

.pr-0 {
  padding-right: 0 !important;
}

.pr-25 {
  padding-right: 25px !important;
}

.location-manager-icon {
  font-size: 14px;
  cursor: pointer;
  transition: .3s;

  &:hover {
    color: #1CA8DD;
  }
}

.location-manager-text {
  padding: 4px 10px !important;
}

.table-container {
  overflow-x: auto;
}

.table-container table {
  width: auto;
}

.resizer {
  display: inline-block;
  background: transparent;
  width: 10px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(50%);
  z-index: 1;
  touch-action: none;
  user-select: none;
  cursor: col-resize;
}

.resizer.isResizing {
  background: #828282;
}

.customize-table-layout-button {
  position: absolute;
  top: -30px;
  right: 8px;
}

.draggable-hovered {
  border: 1px dashed #8c8ca1 !important;
  border-radius: 4px !important;
  background-color: #34364a !important;
  color: #fff !important;
}

.draggable-dragging {
  z-index: 999999 !important;
  background-color: #19a8dd8a !important;
  color: #fff !important;
  box-shadow: 0 4px 8px rgba(0,0,0,0.3);
}

.draggable-dragging .form-check-input,
.draggable-dragging .fa-grip-vertical,
.draggable-dragging .dropdown-item {
  visibility: visible !important;
  opacity: 1 !important;
  color: #fff !important;
}

.draggable-dragging .dropdown-item,
.draggable-dragging .form-check-input {
  color: #fff !important;
}

.customize-table-layout-button.show > .dropdown-menu-table-layout-dropdown-menu {
  z-index: 99 !important;
}

.customize-table-layout-button.show {
  z-index: auto !important;
}

.dropdown-menu-table-layout-dropdown-menu {
  @extend .dropdown-menu;
  background-color: #30343e !important;
  transform: none !important;
  overflow: visible !important;
  padding: 10px 15px;
  min-width: 150px;
  width: 250px;
  max-width: 300px;
}

#column-visibility-menu {
  &:after {
    content: unset !important;
  }
}

.dropdown-menu-table-layout-dropdown-header {
  font-size: 14px;
  margin: 0;
  padding: 5px 8px;
  color: #d0d2da;
}

.dropdown-menu-table-layout-dropdown-menu-label {
  color: #d0d2da;
}

.table-layout-item {
  padding: 2px 10px;
  border-radius: 10px;
}

.action-tooltip {
  opacity: 0;
  transition: opacity 0.2s ease-out;
  background: transparent !important;
  border: none !important;
  padding: 0 !important;
  border-radius: 10px;
}

.action-tooltip.show {
  opacity: 1;
}

.action-tooltip .tooltip-arrow {
  display: none !important;
}

.action-tooltip .tooltip-inner {
  max-width: unset;
  padding: unset;
  color: #252830;
  text-align: unset;
  background-color: transparent;
  border-radius: unset;
}

.action-tooltip-inner-content {
  transition: transform 0.2s ease-out;
  border: none;
  background-color: rgb(48, 52, 62);
  color: rgb(255, 255, 255);
  border-radius: 10px;
  box-shadow: rgb(0 0 0 / 25%) 0px 4px 4px 0px;
  max-width: 450px;
  padding: 14px 16px;
}

.action-tooltip.bs-tooltip-top .action-tooltip-inner-content {
  transform: translateY(-10px);
}
.action-tooltip.bs-tooltip-bottom .action-tooltip-inner-content {
  transform: translateY(5px);
}
.action-tooltip.bs-tooltip-left .action-tooltip-inner-content {
  transform: translateX(-10px);
}
.action-tooltip.bs-tooltip-right .action-tooltip-inner-content {
  transform: translateX(5px);
}

.action-tooltip.show .action-tooltip-inner-content {
  transform: none;
}

.circled-checkbox {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 20px;
  height: 20px;
  min-height: 20px;
  min-width: 20px;
  border-radius: 50%;
  border: 2px solid #ced4da;
  background-color: transparent;
  position: relative;
  cursor: pointer;
  outline: none;
}

.circled-checkbox:checked {
  background-color: #28a745;
  border: none;
}

.circled-checkbox:checked::after {
  content: '';
  position: absolute;
  left: 7px;
  top: 3px;
  width: 6px;
  height: 11px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.circled-checkbox:not(:checked):hover {
  border-color: #5d656d;
}

.circled-checkbox:focus {
  box-shadow: 0 0 0 2px rgba(40, 167, 69, 0.25);
}

.font-italic {
  font-style: italic;
}
